import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import HeaderAdministrativo from "../../components/HeaderAdministrativo";

export default class ParceiroDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    cepService = new CepService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        urlBase: '',
        pessoa: new Pessoa(),
        endereco: new Endereco(),
        listaTabelaPreco: [],
        inputError: {
            nome: false,
            apelido: false,
            tipoDocumento: false,
            documento: false,
            email: false,
            telefone: false,
            tabelaPreco: false,
            cep: false,
            logradouro: false,
            numero: false,
            bairro: false,
            cidade: false,
            uf: false,
        },
        conteudo: '',
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let listaEndereco = [];
        let endereco = new Endereco();

        let parametro = this.util.buscarParametrosUrl();

        let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro.id, this.constante.seqUsuario);
        let pessoa = retorno.objeto;

        retorno = await this.pessoaService.listarEndereco(pessoa);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                endereco = listaEndereco[i];
            }
        }

        this.setState({
            pessoa: pessoa,
            endereco: endereco,
        });
    }

    async salvar() {
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.pessoa.nome) {
            inputError.nome = true;
        }

        if (!this.state.pessoa.apelido) {
            inputError.apelido = true;
        }

        if (!this.state.pessoa.documento) {
            inputError.documento = true;
        } else if (!await this.util.validarDocumento(this.state.pessoa.documento)) {
            inputError.documento = true;
        } else if (this.state.pessoa.tipoDocumento === 'CPF' && this.state.pessoa.documento.length !== 14) {
            inputError.documento = true;
        } else if (this.state.pessoa.tipoDocumento === 'CNPJ' && this.state.pessoa.documento.length !== 18) {
            inputError.documento = true;
        }

        if (!this.state.pessoa.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(this.state.pessoa.emailPlataformaLirida)) {
            inputError.email = true;
        }

        if (!this.state.pessoa.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.util.validarTelefone(this.state.pessoa.telefonePlataformaLirida)) {
            inputError.telefone = true;
        }

        if (!this.state.endereco.cep) {
            inputError.cep = true;
        }

        if (!this.state.endereco.logradouro) {
            inputError.logradouro = true;
        }

        if (!this.state.endereco.numero) {
            inputError.numero = true;
        }

        if (!this.state.endereco.bairro) {
            inputError.bairro = true;
        }

        if (!this.state.endereco.cidade) {
            inputError.cidade = true;
        }

        if (!this.state.endereco.uf) {
            inputError.uf = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({
            inputError: inputError
        });

        if (!inputsOK) {
            return;
        }

        this.setState({ carregando: true });

        let retorno = await this.pessoaService.salvar(this.state.pessoa);
        let pessoa = retorno.objeto;

        retorno = await this.pessoaService.salvarEndereco(this.state.endereco);
        let endereco = retorno.objeto;

        this.toast.current.show({
            severity: 'success', summary: "Informações armazenadas com sucesso!"
        })

        this.setState({
            pessoa: pessoa,
            endereco: endereco,
            carregando: false,
        });
    }

    async buscarCep() {
        let endereco = this.state.endereco;

        let retorno = await this.cepService.buscarCep(this.state.endereco.cep);

        endereco.logradouro = retorno.logradouro;
        endereco.complemento = retorno.complemento;
        endereco.bairro = retorno.bairro;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.uf = retorno.uf;

        this.setState({ endereco: endereco });
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <HeaderAdministrativo />

            <main style={{ padding: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Detalhe da Casa de Festa
                    </h1>

                    <Button
                        disabled={this.state.carregando}
                        loading={this.state.carregando}
                        style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                        label="Salvar"
                        onClick={() => this.salvar()} />
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: this.constante.corSecundaria, textDecorationThickness: 2 }}>
                    <i className="pi pi-user" style={{ color: this.constante.corSecundaria }} /> Dados da Casa de Festa
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Razão Social</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.nome} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Nome Fantasia</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.apelido && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        apelido: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.apelido} />
                            {this.state.inputError.apelido && <small style={{ color: 'red' }}>Nome fantasia inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoa</label><br />
                            <Dropdown
                                disabled
                                style={{ width: '100%' }}
                                options={[
                                    { label: 'Pessoa Física', value: 'CPF' },
                                    { label: 'Pessoa Jurídica', value: 'CNPJ' },
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        tipoDocumento: pEvento.value
                                    }
                                })}
                                value={this.state.pessoa.tipoDocumento} />
                        </div>
                        {this.state.pessoa.tipoDocumento === "CPF" &&
                            <div style={{ width: '100%' }}>
                                <label>CPF</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCPF(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                            </div>}
                        {this.state.pessoa.tipoDocumento === "CNPJ" &&
                            <div style={{ width: '100%' }}>
                                <label>CNPJ</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCNPJ(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                            </div>}
                        <div style={{ width: '100%' }}>
                            <label>Email</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.emailPlataformaLirida} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.telefone && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        telefonePlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.telefonePlataformaLirida} />
                            {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: this.constante.corSecundaria, textDecorationThickness: 2 }}>
                    <i className="pi pi-building" style={{ color: this.constante.corSecundaria }} /> Dados de Endereço
                </h3>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ width: '100%' }}>
                        <label>CEP</label><br />
                        <div className="p-inputgroup">
                            <InputText
                                onChange={pEvento => this.setState({
                                    endereco: {
                                        ...this.state.endereco,
                                        cep: this.util.formatarCEP(pEvento.target.value),
                                    }
                                })}
                                value={this.state.endereco.cep || ''}
                                type="tel"
                                style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                            />
                            <Button
                                style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                                icon="pi pi-search"
                                type="button"
                                onClick={() => this.buscarCep()}
                            />
                        </div>
                        {this.state.inputError.cep && <small style={{ color: 'red' }}>Cep inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Logradouro</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.logradouro && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    logradouro: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.logradouro || ''} />
                        {this.state.inputError.logradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Número</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.numero && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    numero: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.numero || ''} />
                        {this.state.inputError.numero && <small style={{ color: 'red' }}>Número inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Complemento</label><br />
                        <InputText
                            style={{ width: "100%" }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    complemento: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.complemento || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Bairro</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.bairro && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    bairro: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.bairro || ''} />
                        {this.state.inputError.bairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Cidade</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.cidade && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    cidade: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.cidade || ''} />
                        {this.state.inputError.cidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>UF</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.uf && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    uf: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.uf || ''} />
                        {this.state.inputError.uf && <small style={{ color: 'red' }}>Uf inválida</small>}
                    </div>
                </div>
            </main>
        </div>
    }
}