import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Component, createRef } from "react";
import HeaderAdministrativo from "../../components/HeaderAdministrativo";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

export default class Assessor extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    toast = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        pessoa: new Pessoa(),
        listaPessoa: [],
        carregando: false,
        salvarCarregando: false,
        dialogCriarNovoAssessorVisivel: false,
        inputError: {
            nome: false,
            tipoDocumento: false,
            documento: false,
            email: false,
            telefone: false,
        },
    }

    async listar() {
        let listaPesquisa = [];
        let listaPessoa = [];

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilAssessor;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        listaPessoa = retorno.objeto;

        for (let i = 0; i < listaPessoa.length; i++) {
            listaPessoa[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaPessoa[i].dataCadastro);
        }

        this.setState({
            listaPessoa: listaPessoa,
            carregando: false,
        });
    }

    async salvar() {
        let listaPesquisa = [];
        let pessoa = this.state.pessoa;
        let pessoaAssessor = new Pessoa();
        let pessoaAdministrador = new Pessoa();
        let inputError = this.state.inputError;
        let inputsOk = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!pessoa.nome) {
            inputError.nome = true;
        }

        if (!pessoa.tipoDocumento) {
            inputError.tipoDocumento = true;
        }

        if (!pessoa.documento) {
            inputError.documento = true;
        } else if (!await this.util.validarDocumento(pessoa.documento)) {
            inputError.tipoDocumento = true;
        } else if (pessoa.tipoDocumento === 'CPF' && pessoa.documento.length !== 14) {
            inputError.documento = true;
        } else if (pessoa.tipoDocumento === 'CNPJ' && pessoa.documento.length !== 18) {
            inputError.documento = true;
        }

        if (!pessoa.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(pessoa.emailPlataformaLirida)) {
            inputError.email = true;
        }

        if (!pessoa.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.util.validarTelefone(pessoa.telefonePlataformaLirida)) {
            inputError.telefone = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOk = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOk) {
            return;
        }

        this.setState({ salvarCarregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilAssessor;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.email_plataforma_lirida';
        pesquisa.conteudo = pessoa.emailPlataformaLirida;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        pessoaAssessor = retorno.objeto[0];

        if (pessoaAssessor?._seqPessoa) {
            this.toast.current.show({
                severity: 'error', summary: "Não foi possível cadastrar um novo Assessor. O email utilizado já foi cadastrado!"
            });
            return;
        }

        retorno = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaAdministrador, this.constante.seqUsuario);
        pessoaAdministrador = retorno.objeto;

        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilAssessor;
        pessoa.tipoTaxaBoleto = pessoaAdministrador.tipoTaxaBoleto;
        pessoa.tipoTaxaPix = pessoaAdministrador.tipoTaxaPix;
        pessoa.tipoTaxaCredito = pessoaAdministrador.tipoTaxaCredito;
        pessoa.taxaBoleto = pessoaAdministrador.taxaBoleto;
        pessoa.taxaPix = pessoaAdministrador.taxaPix;
        pessoa.taxaCredito = pessoaAdministrador.taxaCredito;

        retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        window.open('/administrativo/assessor_detalhe?id=' + pessoa._seqPessoa);

        this.setState({
            salvarCarregando: false,
            dialogCriarNovoAssessorVisivel: false,
        })
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <Dialog
                style={{margin: 20}}
                header="Criar Novo Assessor"
                visible={this.state.dialogCriarNovoAssessorVisivel}
                onHide={() => this.setState({
                    dialogCriarNovoAssessorVisivel: false,
                })}>
                <form onSubmit={pEvento => { pEvento.preventDefault(); this.salvar(); }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Nome</label>
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.nome || ''} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoa</label>
                            <Dropdown
                                style={{ width: '100%', borderColor: this.state.inputError.tipoDocumento && 'red' }}
                                options={[
                                    { label: 'Pessoa física', value: 'CPF' },
                                    { label: 'Pessoa jurídica', value: 'CNPJ' }
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        tipoDocumento: pEvento.value
                                    }
                                })}
                                value={this.state.pessoa.tipoDocumento || ''} />
                            {this.state.inputError.tipoDocumento && <small style={{ color: 'red' }}>Tipo de pessoa inválida</small>}
                        </div>
                        {this.state.pessoa.tipoDocumento === 'CPF' &&
                            <div style={{ width: '100%' }}>
                                <label>CPF</label>
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCPF(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento || ''} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>CPF inválido</small>}
                            </div>}
                        {this.state.pessoa.tipoDocumento === 'CNPJ' &&
                            <div style={{ width: '100%' }}>
                                <label>CNPJ</label>
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCNPJ(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento || ''} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>CNPJ inválido</small>}
                            </div>}
                        <div style={{ width: '100%' }}>
                            <label>Email</label>
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.emailPlataformaLirida || ''} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label>
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.telefone && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        telefonePlataformaLirida: this.util.formatarTelefone(pEvento.target.value)
                                    }
                                })}
                                value={this.state.pessoa.telefonePlataformaLirida || ''} />
                            {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: 10 }}>
                        <Button
                            disabled={this.state.salvarCarregando}
                            loading={this.state.salvarCarregando}
                            style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                            label="Salvar"
                            type="submit" />
                    </div>
                </form>
            </Dialog>

            <HeaderAdministrativo />

            <main style={{ width: '100%', maxWidth: 1200, padding: 20, margin: '0 auto' }}>
                <div style={{ border: '1px solid lightgray', borderRadius: 5, padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 style={{ fontSize: 20 }}>Assessores</h1>

                    <Button
                        style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                        label="+ Criar Novo Assessor"
                        onClick={() => this.setState({
                            dialogCriarNovoAssessorVisivel: true
                        })} />
                </div>

                <div style={{ border: '1px solid lightgray', borderRadius: 5, padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: 20 }}>
                    <Button
                        disabled={this.state.carregando}
                        loading={this.state.carregando}
                        style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                        label="Buscar"
                        onClick={() => this.listar()} />
                </div>

                <DataTable
                    removableSort
                    style={{ marginTop: 20 }}
                    value={this.state.listaPessoa}
                    selectionMode="single"
                    onSelectionChange={pEvento => window.open('/administrativo/assessor_detalhe?id=' + pEvento.value._seqPessoa)}>
                    <Column
                        header="Nome"
                        field="nome"
                        sortable />
                    <Column
                        header="Email"
                        field="emailPlataformaLirida"
                        sortable />
                    <Column
                        header="Telefone"
                        field="telefonePlataformaLirida"
                        sortable />
                    <Column
                        header="Data de cadastro"
                        field="data_cadastro"
                        sortable
                        body={pPessoa => <p style={{ textAlign: 'right' }}>
                            {pPessoa.dataCadastro?.toLocaleDateString("pt-br")}
                        </p>} />
                </DataTable>
            </main>
        </div>
    }
}