import { Component, createRef } from "react";
import Header from "../components/Header";
import logo from '../images/logo.png';
import { InputText } from 'primereact/inputtext';
import Constante from "../utils/Constante";
import Util from "../utils/Util";
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export default class Login extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    toast = createRef();

    state = {
        email: '',
        senha: '',
        inputError: {
            email: false,
            senha: false,
        }
    }

    async entrar() {
        let listaPesquisa = [];
        let pessoa = new Pessoa();
        let listaPessoa = [];
        let inputError = this.state.inputError;
        let inputsOK = true;

        this.toast.current.clear();

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.email) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(this.state.email)) {
            inputError.email = true;
        }

        if (!this.state.senha) {
            inputError.senha = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.email_plataforma_lirida';
        pesquisa.conteudo = this.state.email;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = " AND pessoa.seq_pessoa_perfil in ('" + this.constante.seqPessoaPerfilAdministrador + "', '" + this.constante.seqPessoaPerfilFornecedor + "') ";
        pesquisa.conteudo = this.constante.seqPessoaPerfilAdministrador;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        console.log(listaPesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        listaPessoa = retorno.objeto;

        console.log(retorno);

        this.setState({ carregando: false });

        for (let i = 0; i < listaPessoa.length; i++) {
            if (listaPessoa[i].seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrador || listaPessoa[i].seqPessoaPerfil === this.constante.seqPessoaPerfilFornecedor) {
                pessoa = listaPessoa[i];
            }
        }

        if (!pessoa._seqPessoa) {
            this.toast.current.show({
                severity: 'error', summary: 'Usuário não encontrado'
            });
            return;
        }

        if (pessoa.senha !== this.state.senha) {
            this.toast.current.show({
                severity: 'error', summary: 'Senha inválida'
            });
            return;
        }

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);

        if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrador) {
            window.location.href = '/administrativo/evento';
            return;
        }

        if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilFornecedor) {
            window.location.href = '/fornecedor/vitrine';
            return;
        }
    }

    render() {
        return <div>
            <Header />

            <Toast ref={this.toast} />

            <main style={{ width: '100%', maxWidth: 1200, display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 20, margin: '100px auto', padding: 20 }}>
                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                        src={logo}
                        style={{ objectFit: 'contain' }} />
                </div>
                <form
                    style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 10 }}
                    onSubmit={pEvento => { pEvento.preventDefault(); this.entrar(); }}>
                    <div style={{ width: '100%' }}>
                        <label>Email</label>
                        <InputText
                            type="email"
                            style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                            onChange={pEvento => this.setState({
                                email: pEvento.target.value
                            })}
                            value={this.state.email} />
                        {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                    </div>
                    <div style={{ width: '100%', }}>
                        <label>Senha</label>
                        <InputText
                            type="password"
                            style={{ width: '100%', borderColor: this.state.inputError.senha && 'red' }}
                            onChange={pEvento => this.setState({
                                senha: pEvento.target.value
                            })}
                            value={this.state.senha} />
                        {this.state.inputError.senha && <small style={{ color: 'red' }}>Senha inválida</small>}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        <Button
                            style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                            disabled={this.state.carregando}
                            loading={this.state.carregando}
                            type="submit"
                            label="Entrar" />
                    </div>
                </form>
            </main>
        </div>
    }
}