import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import HeaderFornecedor from "../../components/HeaderFornecedor";

export default class Perfil extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        urlBase: '',
        carregando: false,
        imagemCarregando: false,
        pessoaUsuario: new Pessoa(),
        inputError: {
            nome: false,
            tipoDocumento: false,
            documento: false,
            email: false,
            telefone: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        let urlBase = await this.util.buscarUrlBaseArquivo();

        console.log(pessoaUsuario);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase,
        });
    }

    async salvar() {
        let pessoaUsuario = this.state.pessoaUsuario;
        let endereco = this.state.endereco;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!pessoaUsuario.nome) {
            inputError.nome = true;
        }

        if (!pessoaUsuario.tipoDocumento) {
            inputError.tipoDocumento = true;
        }

        if (!pessoaUsuario.documento) {
            inputError.documento = true;
        } else if (pessoaUsuario.tipoDocumento === 'CPF' && pessoaUsuario.documento.length !== 14) {
            inputError.documento = true;
        } else if (pessoaUsuario.tipoDocumento === 'CNPJ' && pessoaUsuario.documento.length !== 18) {
            inputError.documento = true;
        }

        if (!pessoaUsuario.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(pessoaUsuario.emailPlataformaLirida)) {
            inputError.email = true;
        }

        if (!pessoaUsuario.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.util.validarTelefone(pessoaUsuario.telefonePlataformaLirida)) {
            inputError.telefone = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        this.setState({ carregando: true });

        pessoaUsuario.seqUsuario = this.constante.seqUsuario;
        pessoaUsuario.seqPessoaPerfil = this.constante.seqPessoaPerfilParceiro;

        let retorno = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retorno.objeto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        this.toast.current.show({
            severity: 'success', summary: 'Informações armazenadas com sucesso!'
        })

        this.setState({
            pessoaUsuario: pessoaUsuario,
            carregando: false,
        });
    }

    async alterarImagem(pArquivo) {
        let pessoa = new Pessoa();
        let pessoaUsuario = this.state.pessoaUsuario;

        this.setState({ imagemCarregando: true });

        pessoa = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        pessoa.foto = await this.util.pegarBase64(pArquivo);

        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        pessoaUsuario.foto = pessoa.foto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            imagemCarregando: false
        });
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <HeaderFornecedor />

            <main style={{ padding: 20, paddingBottom: 200 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Perfil
                    </h1>

                    <Button
                        style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                        disabled={this.state.carregando}
                        loading={this.state.carregando}
                        label="Salvar"
                        onClick={() => this.salvar()} />
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: this.constante.corSecundaria, textDecorationThickness: 2 }}>
                    <i className="pi pi-user" style={{ color: this.constante.corSecundaria }} /> Dados Pessoais
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                        <div>
                            <img
                                style={{ width: 150, height: 150, borderRadius: 500, objectFit: 'contain' }}
                                src={this.state.urlBase + this.state.pessoaUsuario.foto} />
                        </div>

                        <div>
                            <input
                                type="file" id="imagem_item" style={{ display: 'none' }}
                                onChange={pEvento => this.alterarImagem(pEvento.target.files[0])} />
                            {this.state.imagemCarregando ?
                                <Button
                                    disabled={this.state.imagemCarregando}
                                    loading={this.state.imagemCarregando}
                                    icon="pi pi-camera"
                                    label="Alterar imagem" />
                                :
                                <label
                                    htmlFor="imagem_item"
                                    style={{ padding: '15px 20px', borderRadius: 5,cursor: 'pointer', backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria, fontWeight: 'bold' }}
                                >
                                    <i className="pi pi-camera"></i> Alterar imagem
                                </label>}
                        </div>
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Nome</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.nome} />
                                {this.state.inputError.nome && <small style={{color: 'red'}}>Nome inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoa</label><br />
                            <Dropdown
                                style={{ width: '100%', borderColor: this.state.inputError.tipoDocumento && 'red' }}
                                options={[
                                    { label: 'Pessoa Física', value: 'CPF' },
                                    { label: 'Pessoa Jurídica', value: 'CNPJ' },
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        tipoDocumento: pEvento.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.tipoDocumento} />
                                    {this.state.inputError.tipoDocumento && <small style={{color: 'red'}}>Tipo de pessoa inválida</small>}
                        </div>
                        {this.state.pessoaUsuario.tipoDocumento === "CPF" &&
                            <div style={{ width: '100%' }}>
                                <label>CPF</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            documento: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.documento} />
                                    {this.state.inputError.documento && <small style={{color: 'red'}}>Documento inválido</small>}
                            </div>}
                        {this.state.pessoaUsuario.tipoDocumento === "CNPJ" &&
                            <div style={{ width: '100%' }}>
                                <label>CNPJ</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            documento: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.documento} />
                                    {this.state.inputError.documento && <small style={{color: 'red'}}>Documento inválido</small>}
                            </div>}
                        <div style={{ width: '100%' }}>
                            <label>Email</label><br />
                            <InputText
                                disabled
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.emailPlataformaLirida} />
                                {this.state.inputError.email && <small style={{color: 'red'}}>Email inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.telefone && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        telefonePlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.telefonePlataformaLirida} />
                                {this.state.inputError.telefone && <small style={{color: 'red'}}>Telefone inválido</small>}
                        </div>
                    </div>
                </div>
            </main >
        </div >
    }
}