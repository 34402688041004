import { Component } from "react";
import Constante from "../utils/Constante";
import logo from '../images/logo.png';
import { Button } from "primereact/button";
import Util from "../utils/Util";

export default class HeaderFornecedor extends Component {
    constante = new Constante();
    util = new Util();

    render() {
        return <header style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, backgroundColor: this.constante.corPrincipal, }}>
            <img
                src={logo}
                style={{ width: 100, height: 40, objectFit: 'contain' }} />

            <nav style={{ display: 'flex', alignItems: "center", gap: 10, }}>
                <Button
                    style={{ color: this.constante.corSecundaria }}
                    label="Vitrine"
                    className="p-button-link"
                    onClick={() => window.location.href = '/fornecedor/vitrine'} />
                <Button
                    style={{ color: this.constante.corSecundaria }}
                    label="Perfil"
                    className="p-button-link"
                    onClick={() => window.location.href = '/fornecedor/perfil'} />
                <Button
                    style={{ color: this.constante.corSecundaria }}
                    label="Sair"
                    className="p-button-link"
                    onClick={() => {
                        this.util.removerLocalStorage("PESSOA_USUARIO");
                        window.location.href = '/';
                    }} />
            </nav>
        </header>
    }
}