import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Component, createRef } from "react";
import HeaderAdministrativo from "../../components/HeaderAdministrativo";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";

export default class Perfil extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    toast = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        carregando: false,
        inputError: {
            nome: false,
            tipoDocumento: false,
            documento: false,
            email: false,
            telefone: false,
        },
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        this.setState({
            pessoaUsuario: pessoaUsuario,
        });
    }

    async salvar() {
        let pessoaUsuario = this.state.pessoaUsuario;
        let inputError = this.state.inputError;
        let inputsOk = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!pessoaUsuario.nome) {
            inputError.nome = true;
        }

        if (!pessoaUsuario.tipoDocumento) {
            inputError.tipoDocumento = true;
        }

        if (!pessoaUsuario.documento) {
            inputError.documento = true;
        } else if (!await this.util.validarDocumento(pessoaUsuario.documento)) {
            inputError.tipoDocumento = true;
        } else if (pessoaUsuario.tipoDocumento === 'CPF' && pessoaUsuario.documento.length !== 14) {
            inputError.documento = true;
        } else if (pessoaUsuario.tipoDocumento === 'CNPJ' && pessoaUsuario.documento.length !== 18) {
            inputError.documento = true;
        }

        if (!pessoaUsuario.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(pessoaUsuario.emailPlataformaLirida)) {
            inputError.email = true;
        }

        if (!pessoaUsuario.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.util.validarTelefone(pessoaUsuario.telefonePlataformaLirida)) {
            inputError.telefone = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOk = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOk) {
            return;
        }

        this.setState({ carregando: true });

        let retorno = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retorno.objeto;

        this.toast.current.show({
            severity: 'success', summary: 'Informações armazenadas com sucesso!'
        });

        this.setState({
            pessoaUsuario: pessoaUsuario,
            carregando: false
        })
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <HeaderAdministrativo />

            <main style={{ width: '100%', maxWidth: 1200, padding: 20, margin: '0 auto' }}>
                <div style={{ border: '1px solid lightgray', borderRadius: 5, padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 style={{ fontSize: 20 }}>Perfil</h1>

                    <Button
                        disabled={this.state.carregando}
                        loading={this.state.carregando}
                        style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                        label="Salvar"
                        onClick={() => this.salvar()} />
                </div>

                <form
                    style={{ border: '1px solid lightgray', borderRadius: 5, padding: 10, marginTop: 20 }}
                    onSubmit={pEvento => { pEvento.preventDefault(); this.salvar(); }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Nome</label>
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.nome || ''} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoaUsuario</label>
                            <Dropdown
                                style={{ width: '100%', borderColor: this.state.inputError.tipoDocumento && 'red' }}
                                options={[
                                    { label: 'Pessoa física', value: 'CPF' },
                                    { label: 'Pessoa jurídica', value: 'CNPJ' }
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        tipoDocumento: pEvento.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.tipoDocumento || ''} />
                            {this.state.inputError.tipoDocumento && <small style={{ color: 'red' }}>Tipo de pessoaUsuario inválida</small>}
                        </div>
                        {this.state.pessoaUsuario.tipoDocumento === 'CPF' &&
                            <div style={{ width: '100%' }}>
                                <label>CPF</label>
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            documento: this.util.formatarCPF(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.documento || ''} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>CPF inválido</small>}
                            </div>}
                        {this.state.pessoaUsuario.tipoDocumento === 'CNPJ' &&
                            <div style={{ width: '100%' }}>
                                <label>CNPJ</label>
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            documento: this.util.formatarCNPJ(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.documento || ''} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>CNPJ inválido</small>}
                            </div>}
                        <div style={{ width: '100%' }}>
                            <label>Email</label>
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.emailPlataformaLirida || ''} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label>
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.telefone && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        telefonePlataformaLirida: this.util.formatarTelefone(pEvento.target.value)
                                    }
                                })}
                                value={this.state.pessoaUsuario.telefonePlataformaLirida || ''} />
                            {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                        </div>
                    </div>
                </form>
            </main>
        </div>
    }
}