import { Component } from "react";
import Constante from "../utils/Constante";
import logo from '../images/logo.png';

export default class Header extends Component {
    constante = new Constante();

    render() {
        return <header style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, backgroundColor: this.constante.corPrincipal, }}>
            <img
                src={logo}
                style={{ width: 100, height: 40, objectFit: 'contain' }} />
        </header>
    }
}