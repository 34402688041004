import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Item from "lirida-back-service/Servicos/Item/Item";
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Component, createRef } from "react";
import HeaderFornecedor from "../../components/HeaderFornecedor";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';

export default class VitrineDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    itemService = new ItemService(this.constante.token, this.constante.urlBase);
    toast = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        item: new Item(),
        imagemCarregando: false,
        carregando: false,
        inputError: {
            imagem: false,
            nome: false,
            descricao: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let item = new Item();
        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        let urlBase = await this.util.buscarUrlBaseArquivo();
        let parametros = this.util.buscarParametrosUrl();

        let retorno = await this.itemService.buscar(parametros.id);
        console.log(retorno);
        item = retorno.objeto;

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase,
            item: item,
        });
    }

    async alterarImagem(pArquivo) {
        let item = new Item();
        let itemVitrine = this.state.item;

        item = { ...this.state.item };

        this.setState({ imagemCarregando: true });

        item.imagem = await this.util.pegarBase64(pArquivo);

        let retorno = await this.itemService.salvar(item);
        item = retorno.objeto;

        itemVitrine.imagem = item.imagem;

        this.setState({
            item: itemVitrine,
            imagemCarregando: false
        });
    }

    async salvar() {
        let item = this.state.item;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!item.nome) {
            inputError.nome = true;
        }

        if (!item.descricao) {
            inputError.descricao = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                console.log(pKey)
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        this.setState({ carregando: true });

        item.codigo = '123';
        item.seqUsuario = this.constante.seqUsuario;
        item.seqPessoa = this.state.pessoaUsuario._seqPessoa;

        let retorno = await this.itemService.salvar(item);
        item = retorno.objeto;

        console.log(item);

        this.toast.current.show({
            severity: 'success', summary: 'Informações armazenadas com sucesso!'
        });

        this.setState({
            carregando: false,
            item: item,
        });
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <HeaderFornecedor />

            <main style={{ width: '100%', maxWidth: 1200, padding: 20, margin: '0 auto' }}>
                <div style={{ border: '1px solid lightgray', borderRadius: 5, padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 style={{ fontSize: 20 }}>Detalhe do item</h1>

                    <Button
                        style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                        label="Salvar"
                        onClick={() => this.salvar()} />
                </div>

                <div style={{ border: '1px solid lightgray', borderRadius: 5, padding: 10, marginTop: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                        <div>
                            <img
                                style={{ width: 150, height: 150, borderRadius: 500, objectFit: 'contain' }}
                                src={this.state.urlBase + this.state.item.imagem} />
                        </div>

                        <div>
                            <input
                                type="file" id="imagem_item" style={{ display: 'none' }}
                                onChange={pEvento => this.alterarImagem(pEvento.target.files[0])} />
                            {this.state.imagemCarregando ?
                                <Button
                                    style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                                    disabled={this.state.imagemCarregando}
                                    loading={this.state.imagemCarregando}
                                    icon="pi pi-camera"
                                    label="Alterar imagem" />
                                :
                                <label
                                    htmlFor="imagem_item"
                                    style={{ fontWeight: 'bold', padding: '14px 24px', borderRadius: 5, cursor: 'pointer', backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria, }}
                                >
                                    <i className="pi pi-camera"></i> Alterar imagem
                                </label>}
                        </div>
                    </div>

                    <div style={{ width: '100%', marginTop: 20 }}>
                        <label>Nome</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                            onChange={pEvento => this.setState({
                                item: {
                                    ...this.state.item,
                                    nome: pEvento.target.value
                                }
                            })}
                            value={this.state.item.nome || ''} />
                        {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                    </div>
                    <div style={{ width: '100%', marginTop: 10 }}>
                        <label>Descrição</label><br />
                        <InputTextarea
                            style={{ width: '100%', borderColor: this.state.inputError.descricao && 'red' }}
                            autoResize
                            onChange={pEvento => this.setState({
                                item: {
                                    ...this.state.item,
                                    descricao: pEvento.target.value
                                }
                            })}
                            value={this.state.item.descricao || ''} />
                        {this.state.inputError.descricao && <small style={{ color: 'red' }}>Descrição inválido</small>}
                    </div>
                </div>
            </main>
        </div>
    }
}