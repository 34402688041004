import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Login from './pages/Login';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Fornecedor from './pages/administrativo/Fornecedor';
import CasaDeFesta from './pages/administrativo/CasaDeFesta';
import Assessor from './pages/administrativo/Assessor';
import PerfilAdministrativo from './pages/administrativo/Perfil';
import Vitrine from './pages/fornecedor/Vitrine';
import PerfilFornecedor from './pages/fornecedor/Perfil';
import Evento from './pages/administrativo/Evento';
import CasaDeFestaDetalhe from './pages/administrativo/CasaDeFestaDetalhe';
import EventoDetalhe from './pages/administrativo/EventoDetalhe';
import { locale, addLocale } from 'primereact/api';
import FornecedorDetalhe from './pages/administrativo/FornecedorDetalhe';
import VitrineDetalhe from './pages/fornecedor/VitrineDetalhe';
import AssessorDetalhe from './pages/administrativo/AssessorDetalhe';

/* Alterando lingua de en pra pt calendario */
addLocale('br', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
  dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
  monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
  today: 'Hoje',
  clear: 'Limpar'
});

locale('br');

class Rota extends Component {
  render() {
    return window.location.pathname === this.props.path && this.props.component
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<>
  <Rota path="/" component={<Login />} />

  <Rota path="/administrativo/evento" component={<Evento />} />
  <Rota path="/administrativo/evento_detalhe" component={<EventoDetalhe />} />
  <Rota path="/administrativo/fornecedor" component={<Fornecedor />} />
  <Rota path="/administrativo/fornecedor_detalhe" component={<FornecedorDetalhe />} />
  <Rota path="/administrativo/casa_de_festa" component={<CasaDeFesta />} />
  <Rota path="/administrativo/casa_de_festa_detalhe" component={<CasaDeFestaDetalhe />} />
  <Rota path="/administrativo/assessor" component={<Assessor />} />
  <Rota path="/administrativo/assessor_detalhe" component={<AssessorDetalhe />} />
  <Rota path="/administrativo/perfil" component={<PerfilAdministrativo />} />

  <Rota path="/fornecedor/vitrine" component={<Vitrine />} />
  <Rota path="/fornecedor/vitrine_detalhe" component={<VitrineDetalhe />} />
  <Rota path="/fornecedor/perfil" component={<PerfilFornecedor />} />
</>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
