export default class Constante {
    // Api
    token = '123';
    urlBase = 'https://api.lirida.com.br/v1/api/';
    // urlBase = 'http://192.168.1.19:8080/v1/api/'; 

    // Plataforma
    seqUsuario = "2a3491aa-516a-4109-bc94-b1391b266cde";

    // Cores
    corPrincipal = '#21242D';
    corSecundaria = '#e8a81e';

    // // Admin
    seqPessoaAdministrador = 'b303126b-77a7-4ca7-b1e6-a8e3be5f9e3c';

    // Perfil
    seqPessoaPerfilAdministrador = "02058098-9992-442d-9e60-f126f2156f6d";
    seqPessoaPerfilFornecedor = "d2dd0a86-6184-4030-a7cd-d74bc1a914c9";
    seqPessoaPerfilCliente = "29f3e958-d0cc-4b74-8511-76b4494fdb4e";
    seqPessoaPerfilAssessor = "1612822a-1648-4207-8d20-22c09c3e9c5e";
    seqPessoaPerfilCasaDeFesta = '4b1f9d75-e7cd-4648-877b-b531cea5fc06';

    // // Tipo contato
    // seqTipoContatoTelefone = "215e44df-7fb0-46ba-81ea-fc47984aa7ac";
    // seqTipoContatoEmail = "787b95b2-d72b-46b4-8f94-9ec19f58b117";
    seqTipoContatoWhatsApp = "40c9d9fa-df1f-49f1-8f90-59dc2001f093";
    seqTipoContatoInstagram = "84c45973-ac60-45f3-ac10-e5c0b8822537";
    // seqTipoContatoFacebook = "3a9b7211-371f-4534-9c2f-a3cd44c9e866";
    // seqTipoContatoSite = "d09ef7d1-675b-4047-adeb-ebda83102e7a";

    // // Endereco
    seqTipoEnderecoCobranca = "b4062983-6166-4c91-8319-a25287b505b2";
    seqTipoEnderecoEntrega = "7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09";

    // // Tipo documento
    seqTipoDocumentoEvento = "0343f3e3-ca47-4b1f-b467-a636829bc139";

    // Tipo relacionamento
    seqTipoRelacionamentoFornecedorRecomendado = 'dab48d84-c918-4e4e-8970-22a9837e5d56';
    seqTipoRelacionamentoFornecedorFavorito = '817a75f8-3be1-4703-b410-50f4275b2481';
    seqTipoRelacionamentoFornecedorContratado = 'b4283b24-2598-48d8-bf5a-2d1f2c3255ba';

    // Tipo documento processo
    seqTipoDocumentoProcessoDigitado = "01ff2df7-4af5-4f45-ac5c-4c8d4210b8b6";
    // seqTipoDocumentoProcessoRecebendoProposta = "d31a7be0-9c9d-4d7c-be53-95b4e8e8f21c";
    // seqTipoDocumentoProcessoAguardandoAceiteCliente = "31bc64d2-1448-4d9d-85d5-ea80eaf1fb70";
    // seqTipoDocumentoProcessoAguardandoDiaEvento = "cde1d165-a25d-4e32-a4d4-216c6c92d40b";
    // seqTipoDocumentoProcessoPropostaReprovada = "ed10cfef-5604-4355-abe7-1e6ce60db9d1";

    // // Item categoria
    // seqItemCategoria = "deece6a3-0e47-4644-b2ed-e2d6f65c7d14";

    // // Tabela de preço
    // seqTabelaPrecoPrincipal = "39026d9b-1eb2-46fc-9dad-a08687b0ec8c";

    // // Tipo arquivo
    // seqTipoArquivoInformacaoAdicional = "ed30a166-69ea-41d9-b990-e2cc522d231d"

    // // Tipo atendimento
    // seqTipoAtendimentoInformacaoAdicional = "317be2e7-0329-4574-8cca-9911f46b8863"

    // // Email
    // seqEmailAcesso = "728f2513-b464-46cd-8815-8750a14d7212";
    // seqEmailAceiteCliente = "5ec5a43c-1d20-43b7-81de-260f3b85807a";

    // // Conta bancária
    // seqContaBancariaPrincipal = "52180c3b-ab43-4576-a7c4-46a2ad374c53"

    // // Conta financeira
    // seqContaFinanceiraEntrada = "117b1797-e5c9-4c0b-9f14-fa95fb0202dd"
    // seqContaFinanceiraReceita = "45535d7c-2608-4a37-9aed-bac11d018e08"
    // seqContaFinanceiroPagamentoFornecedor = "437a0f61-fa4d-4a3a-b58b-7f82c16fdbe1"

    // // Contas a pagar
    // quantidadeDiasVencimentoContasPagar = 10;

    // // Condicao pagamento
    // seqCondicaoPagamentoAVista = '3cd67494-a665-4f56-a8a4-e5041c0f348c';

    // // Forma de pagamento
    // seqFormaPagamentoPix = "4a2f63b0-7ddb-4f92-a3e3-904831642415"
    // seqFormaPagamentoBoleto = "655b80ce-68a9-40b6-9454-1e22284a525f"
    // seqFormaPagamentoCartaoCredito = "b478379c-b8d9-4724-a1a0-fdb76426d45e"

    // // ID Listar lista
    // idListaContaBancaria = 'CB'
    // idListaContaFinanceira = 'CF'
    // idListaFormaPagamento = 'FP'
}