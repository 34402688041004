import Item from "lirida-back-service/Servicos/Item/Item";
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Component, createRef } from "react";
import HeaderFornecedor from "../../components/HeaderFornecedor";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";

export default class Vitrine extends Component {
    constante = new Constante();
    util = new Util();
    itemService = new ItemService(this.constante.token, this.constante.urlBase);
    toast = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        urlBase: '',
        item: new Item(),
        listaItem: [],
        carregando: false,
        dialogCriarNovoItemVisivel: false,
        inputError: {
            nome: false,
            descricao: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        let urlBase = await this.util.buscarUrlBaseArquivo();

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase
        });
    }

    async listar() {
        let listaPesquisa = [];
        let listaItem = [];

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'item.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'item.seq_pessoa';
        pesquisa.conteudo = this.state.pessoaUsuario._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.itemService.listarComFiltro(listaPesquisa);
        listaItem = retorno.objeto;

        for (let i = 0; i < listaItem.length; i++) {
            listaItem[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaItem.dataCadastro);
        }

        this.setState({
            listaItem: listaItem,
            carregando: false,
        });
    }

    async salvar() {
        let item = this.state.item;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!item.nome) {
            inputError.nome = true;
        }

        if (!item.descricao) {
            inputError.descricao = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                console.log(pKey)
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        this.setState({ salvarCarregando: true });

        item.codigo = String(Math.random() + 1).replace(".", '');
        item.seqUsuario = this.constante.seqUsuario;
        item.seqPessoa = this.state.pessoaUsuario._seqPessoa;

        let retorno = await this.itemService.salvar(item);
        item = retorno.objeto;

        this.toast.current.show({
            severity: 'success', summary: 'Informações armazenadas com sucesso!'
        });

        this.setState({
            item: item,
            salvarCarregando: false,
            dialogCriarNovoItemVisivel: false,
        });
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <Dialog
                style={{ width: '100%', maxWidth: 1200 }}
                header="Criar novo item"
                visible={this.state.dialogCriarNovoItemVisivel}
                onHide={() => this.setState({
                    dialogCriarNovoItemVisivel: false,
                })}>
                <div>
                    {/* <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                        <div>
                            <img
                                style={{ width: 150, height: 150, borderRadius: 500, objectFit: 'contain' }}
                                src={this.state.urlBase + this.state.item.imagem} />
                        </div>

                        <div>
                            <input
                                type="file" id="imagem_item" style={{ display: 'none' }}
                                onChange={pEvento => this.alterarImagem(pEvento.target.files[0])} />
                            {this.state.imagemCarregando ?
                                <Button
                                    style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                                    disabled={this.state.imagemCarregando}
                                    loading={this.state.imagemCarregando}
                                    icon="pi pi-camera"
                                    label="Alterar imagem" />
                                :
                                <label
                                    htmlFor="imagem_item"
                                    style={{ fontWeight: 'bold', padding: '14px 24px', borderRadius: 5, cursor: 'pointer', backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria, }}
                                >
                                    <i className="pi pi-camera"></i> Alterar imagem
                                </label>}
                        </div>
                    </div> */}

                    <div style={{ width: '100%', marginTop: 20 }}>
                        <label>Nome</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                            onChange={pEvento => this.setState({
                                item: {
                                    ...this.state.item,
                                    nome: pEvento.target.value
                                }
                            })}
                            value={this.state.item.nome || ''} />
                        {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                    </div>
                    <div style={{ width: '100%', marginTop: 10 }}>
                        <label>Descrição</label><br />
                        <InputTextarea
                            style={{ width: '100%', borderColor: this.state.inputError.descricao && 'red' }}
                            autoResize
                            onChange={pEvento => this.setState({
                                item: {
                                    ...this.state.item,
                                    descricao: pEvento.target.value
                                }
                            })}
                            value={this.state.item.descricao || ''} />
                        {this.state.inputError.descricao && <small style={{ color: 'red' }}>Descrição inválido</small>}
                    </div>
                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'right', marginTop: 10 }}>
                        <Button
                            disabled={this.state.salvarCarregando}
                            loading={this.state.salvarCarregando}
                            style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                            label="Salvar"
                            onClick={() => this.salvar()} />
                    </div>
                </div>
            </Dialog>

            <HeaderFornecedor />

            <main style={{ width: '100%', maxWidth: 1200, padding: 20, margin: '0 auto' }}>
                <div style={{ border: '1px solid lightgray', borderRadius: 5, padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 style={{ fontSize: 20 }}>Vitrine</h1>

                    <Button
                        style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                        label="+ Criar novo item"
                        onClick={() => this.setState({
                            item: new Item(),
                            dialogCriarNovoItemVisivel: true,
                        })} />
                </div>

                <div style={{ border: '1px solid lightgray', borderRadius: 5, padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: 20 }}>
                    <Button
                        disabled={this.state.carregando}
                        loading={this.state.carregando}
                        style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal, color: this.constante.corSecundaria }}
                        label="Buscar"
                        onClick={() => this.listar()} />
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    value={this.state.listaItem}
                    selectionMode="single"
                    onSelectionChange={pEvento => window.open('/fornecedor/vitrine_detalhe?id=' + pEvento.value._seqItem)}>
                    <Column
                        header="Foto"
                        field="imagem"
                        sortable
                        body={pItem => <img
                            style={{ width: 100, height: 100, borderRadius: 500, objectFit: 'contain' }}
                            src={this.state.urlBase + pItem.imagem} />} />
                    <Column
                        header="Nome"
                        field="nome"
                        sortable />
                    <Column
                        header="Descrição"
                        field="descricao"
                        sortable />
                    <Column
                        header="Data de Cadastro"
                        field="dataCadastro"
                        sortable
                        body={pItem => <p>
                            {pItem.dataCadastro?.toLocaleDateString("pt-br")}
                        </p>} />
                </DataTable>
            </main>
        </div>
    }
}