import { Button } from "primereact/button";
import { Component, createRef } from "react";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Steps } from "primereact/steps";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import { Toast } from "primereact/toast";
import HeaderAdministrativo from "../../components/HeaderAdministrativo";

export default class Evento extends Component {
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    cepService = new CepService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        pessoaCasaDeFesta: new Pessoa(),
        pessoaAssessor: new Pessoa(),
        listaPessoaCasaDeFesta: [],
        listaPessoaAssessor: [],
        listaDocumento: [],
        carregando: false,
        conteudo: '',
        conteudoEmail: '',
        dataCadastroInicial: undefined,
        dataCadastroFinal: undefined,
        dataEventoInicial: undefined,
        dataEventoFinal: undefined,
        dialogNovoEvento: false,
        indexStepAtivo: 0,
        pessoa: new Pessoa(),
        enderecoCobranca: new Endereco(),
        enderecoEntrega: new Endereco(),
        documento: new Documento(),
        inputError: {
            nome: false,
            tipoDocumento: false,
            documento: false,
            email: false,
            telefone: false,
            enderecoCobrancaCep: false,
            enderecoCobrancaLogradouro: false,
            enderecoCobrancaNumero: false,
            enderecoCobrancaBairro: false,
            enderecoCobrancaCidade: false,
            enderecoCobrancaUf: false,
            numeroContrato: false,
            casaDeFesta: false,
            dataEvento: false,
            observacao: false,
            assessor: false,
            enderecoEntregaCep: false,
            enderecoEntregaLogradouro: false,
            enderecoEntregaNumero: false,
            enderecoEntregaBairro: false,
            enderecoEntregaCidade: false,
            enderecoEntregaUf: false,
        },
        salvarCarregando: false,
        enderecoCobrancaCarregando: false,
        enderecoEntregaCarregando: false,
        usarEnderecoDaCasaDeFestaCarregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilCasaDeFesta;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        let listaPessoaCasaDeFesta = retorno.objeto;

        listaPesquisa.pop();

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilAssessor;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        let listaPessoaAssessor = retorno.objeto;

        this.setState({
            listaPessoaCasaDeFesta: listaPessoaCasaDeFesta,
            listaPessoaAssessor: listaPessoaAssessor,
        });
    }

    async listar() {
        let listaDocumento = [];
        let listaPesquisa = [];

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_tipo_documento';
        pesquisa.conteudo = this.constante.seqTipoDocumentoEvento;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario_vendedor';
        pesquisa.conteudo = this.state.pessoaCasaDeFesta._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        if (this.state.conteudo) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.nome';
            pesquisa.conteudo = this.state.conteudo;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.conteudoEmail) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.email_plataforma_lirida';
            pesquisa.conteudo = this.state.conteudoEmail;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_cadastro';
            pesquisa.conteudo = this.state.dataCadastroInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_cadastro';
            pesquisa.conteudo = this.state.dataCadastroFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEventoInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_programada';
            pesquisa.conteudo = this.state.dataEventoInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEventoFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_programada';
            pesquisa.conteudo = this.state.dataEventoFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        listaDocumento = retorno.objeto;

        for (let i = 0; i < listaDocumento.length; i++) {
            listaDocumento[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaDocumento[i].dataCadastro);
            listaDocumento[i].dataProgramada = this.util.formatarDataBancoParaObjetoData(listaDocumento[i].dataProgramada);
        }

        this.setState({
            carregando: false,
            listaDocumento: listaDocumento,
        })
    }

    async salvar() {
        let pessoa = this.state.pessoa;
        let documento = this.state.documento;
        let enderecoCobranca = this.state.enderecoCobranca;
        let enderecoEntrega = this.state.enderecoEntrega;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (this.state.indexStepAtivo === 0) {
            if (!pessoa.nome) {
                inputError.nome = true;
            }

            if (!pessoa.tipoDocumento) {
                inputError.tipoDocumento = true;
            }

            if (!pessoa.documento) {
                inputError.documento = true;
            } else if (pessoa.tipoDocumento === 'CPF' && pessoa.documento.length < 14) {
                inputError.documento = true;
            } else if (pessoa.tipoDocumento === 'CNPJ' && pessoa.documento.length < 18) {
                inputError.documento = true;
            } else if (!await this.util.validarDocumento(pessoa.documento)) {
                inputError.documento = true;
            }

            if (!pessoa.emailPlataformaLirida) {
                inputError.email = true;
            }

            if (!this.util.validarEmail(pessoa.emailPlataformaLirida)) {
                inputError.email = true;
            }

            if (!pessoa.telefonePlataformaLirida) {
                inputError.telefone = true;
            }

            if (!enderecoCobranca.cep) {
                inputError.enderecoCobrancaCep = true;
            }

            if (!this.util.validarCep(enderecoCobranca.cep)) {
                inputError.enderecoCobrancaCep = true;
            }

            if (!enderecoCobranca.logradouro) {
                inputError.enderecoCobrancaLogradouro = true;
            }

            if (!enderecoCobranca.numero) {
                inputError.enderecoCobrancaNumero = true;
            }

            if (!enderecoCobranca.bairro) {
                inputError.enderecoCobrancaBairro = true;
            }

            if (!enderecoCobranca.cidade) {
                inputError.enderecoCobrancaCidade = true;
            }

            if (!enderecoCobranca.uf) {
                inputError.enderecoCobrancaUf = true;
            }
        }

        if (this.state.indexStepAtivo === 1) {
            if (!documento.tags0) {
                inputError.numeroContrato = true;
            }

            if (!this.state.pessoaCasaDeFesta._seqPessoa) {
                inputError.casaDeFesta = true;
            }

            if (!documento.dataProgramada) {
                inputError.dataEvento = true;
            }
        }

        if (this.state.indexStepAtivo === 2) {
            if (!enderecoEntrega.cep) {
                inputError.enderecoEntregaCep = true;
            }

            if (!this.util.validarCep(enderecoEntrega.cep)) {
                inputError.enderecoEntregaCep = true;
            }

            if (!enderecoEntrega.logradouro) {
                inputError.enderecoEntregaLogradouro = true;
            }

            if (!enderecoEntrega.numero) {
                inputError.enderecoEntregaNumero = true;
            }

            if (!enderecoEntrega.bairro) {
                inputError.enderecoEntregaBairro = true;
            }

            if (!enderecoEntrega.cidade) {
                inputError.enderecoEntregaCidade = true;
            }

            if (!enderecoEntrega.uf) {
                inputError.enderecoEntregaUf = true;
            }
        }

        if (this.state.indexStepAtivo === 3) {
            if (!this.state.pessoaAssessor._seqPessoa) {
                inputError.assessor = true;
            }
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({
            inputError: inputError
        });

        if (!inputsOK) {
            return;
        }

        if (this.state.indexStepAtivo < 3) {
            this.setState({
                indexStepAtivo: this.state.indexStepAtivo + 1
            });
            return;
        }

        this.setState({ salvarCarregando: true });

        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilCliente;

        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        enderecoCobranca.seqUsuario = this.constante.seqUsuario;
        enderecoCobranca.seqPessoa = pessoa._seqPessoa;

        retorno = await this.pessoaService.salvarEndereco(enderecoCobranca);
        enderecoCobranca = retorno.objeto;

        enderecoEntrega.seqUsuario = this.constante.seqUsuario;
        enderecoEntrega.seqPessoa = pessoa._seqPessoa;

        retorno = await this.pessoaService.salvarEndereco(enderecoEntrega);
        enderecoEntrega = retorno.objeto;

        documento.seqUsuario = this.constante.seqUsuario;
        documento.seqTipoDocumento = this.constante.seqTipoDocumentoEvento;
        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoDigitado;
        documento.seqPessoa = pessoa._seqPessoa;
        documento.seqUsuarioVendedor = this.state.pessoaCasaDeFesta._seqPessoa;
        documento.seqPessoaPagador = this.state.pessoa._seqPessoa;
        documento.seqPessoaRecebedor = this.constante.seqPessoaAdministrador;
        documento.seqFormaPagamento = this.constante.seqFormaPagamentoBoleto;
        documento.dataProgramada = documento.dataProgramada.toLocaleDateString("pt-br").split("/").join("-") + 'T00:00:00';
        documento.tags1 = this.state.pessoaAssessor._seqPessoa;

        retorno = await this.documentoService.salvar(documento);
        documento = retorno.objeto;

        window.open("/administrativo/evento_detalhe?id=" + documento._seqDocumento);

        this.setState({
            salvarCarregando: false,
            dialogNovoEvento: false,
        })
    }

    async buscarCep(pEndereco) {
        let endereco = new Endereco();
        let enderecoCobranca = this.state.enderecoCobranca;
        let enderecoEntrega = this.state.enderecoEntrega;
        let inputError = this.state.inputError;
        let inputsOK = true;

        endereco = pEndereco;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
            this.setState({
                enderecoCobrancaCarregando: true
            });

            if (!endereco.cep) {
                inputError.enderecoCobrancaCep = true;
            }

            if (!this.util.validarCep(endereco.cep)) {
                inputError.enderecoCobrancaCep = true;
            }
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
            this.setState({
                enderecoEntregaCarregando: true
            });

            if (!endereco.cep) {
                inputError.enderecoEntregaCep = true;
            }

            if (!this.util.validarCep(endereco.cep)) {
                inputError.enderecoEntregaCep = true;
            }
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
            this.setState({ enderecoCobrancaCarregando: true });
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
            this.setState({ enderecoEntregaCarregando: true });
        }

        let retorno = await this.cepService.buscarCep(endereco.cep);

        endereco.logradouro = retorno.logradouro;
        endereco.complemento = retorno.complemento;
        endereco.bairro = retorno.bairro;
        endereco.cidade = retorno.localidade;
        endereco.uf = retorno.uf;

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
            enderecoCobranca = endereco;
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
            enderecoEntrega = endereco;
        }

        this.setState({
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            enderecoCobrancaCarregando: false,
            enderecoEntregaCarregando: false,
        });
    }

    async usarEnderecoDaCasaDeFesta() {
        let listaEndereco = [];
        let enderecoEntrega = this.state.enderecoEntrega;

        this.setState({ usarEnderecoDaCasaDeFestaCarregando: true });

        let retorno = await this.pessoaService.listarEndereco(this.state.pessoaCasaDeFesta);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                enderecoEntrega = listaEndereco[i];
            }
        }

        delete enderecoEntrega._seqEndereco;
        enderecoEntrega.seqTipoEndereco = this.constante.seqTipoEnderecoEntrega;

        this.setState({
            usarEnderecoDaCasaDeFestaCarregando: false,
            enderecoEntrega: enderecoEntrega,
        })
    }

    render() {
        return <div>
            <Dialog
                header="Novo Evento"
                visible={this.state.dialogNovoEvento}
                onHide={() => this.setState({
                    dialogNovoEvento: false,
                })}>
                <Steps
                    readOnly
                    model={[
                        { label: 'Dados do Cliente' },
                        { label: 'Dados do Evento' },
                        { label: 'Local do Evento' },
                        { label: 'Assessoria' },
                    ]}
                    activeIndex={this.state.indexStepAtivo} />

                <form
                    style={{ width: '100%', maxWidth: 1000 }}
                    onSubmit={pEvento => { pEvento.preventDefault(); this.salvar(); }}>
                    {this.state.indexStepAtivo === 0 &&
                        <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                            <h4 style={{ fontSize: 16, }}>
                                <span style={{ color: this.constante.corSecundaria }}>•</span> Dados Pessoais
                            </h4>

                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                                <div style={{ width: '100%' }}>
                                    <label>Nome</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.nome && 'red' }}
                                        onChange={pEvento => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa,
                                                nome: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.pessoa.nome || ''} />
                                    {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                                </div>
                                <div style={{ width: '100%' && 'red' }}>
                                    <label>Tipo de pessoa</label><br />
                                    <Dropdown
                                        options={[
                                            { label: 'Pessoa física', value: 'CPF' },
                                            { label: 'Pessoa jurídica', value: 'CNPJ' },
                                        ]}
                                        onChange={pEvento => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa,
                                                tipoDocumento: pEvento.value
                                            }
                                        })}
                                        style={{ width: "100%", borderColor: this.state.inputError.tipoDocumento && 'red' }}
                                        value={this.state.pessoa.tipoDocumento || ''} />
                                    {this.state.inputError.tipoDocumento && <small style={{ color: 'red' }}>Tipo de pessoa inválido</small>}
                                </div>
                                {this.state.pessoa.tipoDocumento === 'CPF' &&
                                    <div style={{ width: '100%' }}>
                                        <label>CPF</label><br />
                                        <InputText
                                            style={{ width: "100%", borderColor: this.state.inputError.documento && 'red' }}
                                            onChange={pEvento => this.setState({
                                                pessoa: {
                                                    ...this.state.pessoa,
                                                    documento: this.util.formatarCPF(pEvento.target.value)
                                                }
                                            })}
                                            value={this.state.pessoa.documento || ''} />
                                        {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                                    </div>}
                                {this.state.pessoa.tipoDocumento === 'CNPJ' &&
                                    <div style={{ width: '100%' }}>
                                        <label>CNPJ</label><br />
                                        <InputText
                                            style={{ width: "100%", borderColor: this.state.inputError.documento && 'red' }}
                                            onChange={pEvento => this.setState({
                                                pessoa: {
                                                    ...this.state.pessoa,
                                                    documento: this.util.formatarCNPJ(pEvento.target.value)
                                                }
                                            })}
                                            value={this.state.pessoa.documento || ''} />
                                        {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                                    </div>}
                                <div style={{ width: '100%' }}>
                                    <label>Email</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.email && 'red' }}
                                        onChange={pEvento => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa,
                                                emailPlataformaLirida: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.pessoa.emailPlataformaLirida || ''} />
                                    {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Telefone</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.telefone && 'red' }}
                                        onChange={pEvento => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa,
                                                telefonePlataformaLirida: this.util.formatarTelefone(pEvento.target.value)
                                            }
                                        })}
                                        value={this.state.pessoa.telefonePlataformaLirida || ''} />
                                    {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                                </div>
                            </div>

                            <h4 style={{ fontSize: 16, marginTop: 20, }}>
                                <span style={{ color: this.constante.corSecundaria }}>•</span> Dados de Endereço
                            </h4>

                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                                <div style={{ width: '100%' }}>
                                    <label>CEP</label><br />
                                    <div className="p-inputgroup">
                                        <InputText
                                            value={this.state.enderecoCobranca.cep || ''}
                                            type="tel"
                                            style={{ width: '100%', borderColor: this.state.inputError.enderecoCobrancaCep && 'red' }}
                                            onChange={pEvento => this.setState({
                                                enderecoCobranca: {
                                                    ...this.state.enderecoCobranca,
                                                    cep: this.util.formatarCEP(pEvento.target.value)
                                                }
                                            })}
                                        />
                                        <Button
                                            disabled={this.state.enderecoCobrancaCarregando}
                                            loading={this.state.enderecoCobrancaCarregando}
                                            style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                                            icon="pi pi-search"
                                            type="button"
                                            onClick={() => this.buscarCep(this.state.enderecoCobranca)}
                                        />
                                    </div>
                                    {this.state.inputError.enderecoCobrancaCep && <small style={{ color: 'red' }}>Cep inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Logradouro</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaLogradouro && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                logradouro: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.logradouro || ''} />
                                    {this.state.inputError.enderecoCobrancaLogradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Número</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaNumero && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                numero: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.numero || ''} />
                                    {this.state.inputError.enderecoCobrancaNumero && <small style={{ color: 'red' }}>Número inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Complemento</label><br />
                                    <InputText
                                        style={{ width: "100%" }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                complemento: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.complemento || ''} />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Bairro</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaBairro && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                bairro: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.bairro || ''} />
                                    {this.state.inputError.enderecoCobrancaBairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Cidade</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaCidade && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                cidade: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.cidade || ''} />
                                    {this.state.inputError.enderecoCobrancaCidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>UF</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaUf && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                uf: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.uf || ''} />
                                    {this.state.inputError.enderecoCobrancaUf && <small style={{ color: 'red' }}>Uf inválido</small>}
                                </div>
                            </div>
                        </div>}

                    {this.state.indexStepAtivo === 1 && <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, }}>
                            <div style={{ width: '100%' }}>
                                <label>Número do contrato</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.numeroContrato && 'red' }}
                                    onChange={pEvento => this.setState({
                                        documento: {
                                            ...this.state.documento,
                                            tags0: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.documento.tags0 || ''} />
                                {this.state.inputError.numeroContrato && <small style={{ color: 'red' }}>Número do contrato inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Casa de Festa</label><br />
                                <Dropdown
                                    options={this.state.listaPessoaCasaDeFesta}
                                    optionLabel="nome"
                                    onChange={pEvento => this.setState({
                                        pessoaCasaDeFesta: pEvento.value
                                    })}
                                    style={{ width: "100%", borderColor: this.state.inputError.casaDeFesta && 'red' }}
                                    value={this.state.pessoaCasaDeFesta || ''} />
                                {this.state.inputError.casaDeFesta && <small style={{ color: 'red' }}>Casa de Festa inválida</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Data do evento</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    inputStyle={{ borderColor: this.state.inputError.dataEvento && 'red' }}
                                    style={{ width: "100%" }}
                                    onChange={pEvento => {
                                        this.setState({
                                            documento: {
                                                ...this.state.documento,
                                                dataProgramada: pEvento.value
                                            }
                                        })
                                    }}
                                    value={this.state.documento.dataProgramada} />
                                {this.state.inputError.dataEvento && <small style={{ color: 'red' }}>Data do evento inválido</small>}
                            </div>
                        </div>

                        <div style={{ width: '100%', marginTop: 10 }}>
                            <label>Observação</label><br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.observacao && 'red' }}
                                onChange={pEvento => this.setState({
                                    documento: {
                                        ...this.state.documento,
                                        observacao: pEvento.target.value
                                    }
                                })}
                                value={this.state.documento.observacao || ''} />
                            {this.state.inputError.observacao && <small style={{ color: 'red' }}>Observação inválida</small>}
                        </div>
                    </div>}

                    {this.state.indexStepAtivo === 2 &&
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                            <div style={{ width: '100%' }}>
                                <label>CEP</label><br />
                                <div className="p-inputgroup">
                                    <InputText
                                        value={this.state.enderecoEntrega.cep || ''}
                                        type="tel"
                                        style={{ width: '100%', borderColor: this.state.inputError.enderecoEntregaCep && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoEntrega: {
                                                ...this.state.enderecoEntrega,
                                                cep: this.util.formatarCEP(pEvento.target.value)
                                            }
                                        })}
                                    />
                                    <Button
                                        disabled={this.state.enderecoEntregaCarregando}
                                        loading={this.state.enderecoEntregaCarregando}
                                        style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                                        icon="pi pi-search"
                                        type="button"
                                        onClick={() => this.buscarCep(this.state.enderecoEntrega)}
                                    />
                                </div>
                                {this.state.inputError.enderecoEntregaCep && <small style={{ color: 'red' }}>Cep inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Logradouro</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaLogradouro && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            logradouro: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.logradouro || ''} />
                                {this.state.inputError.enderecoEntregaLogradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Número</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaNumero && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            numero: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.numero || ''} />
                                {this.state.inputError.enderecoEntregaNumero && <small style={{ color: 'red' }}>Número inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Complemento</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            complemento: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.complemento || ''} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Bairro</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaBairro && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            bairro: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.bairro || ''} />
                                {this.state.inputError.enderecoEntregaBairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Cidade</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaCidade && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            cidade: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.cidade || ''} />
                                {this.state.inputError.enderecoEntregaCidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Estado</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaEstado && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            estado: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.estado || ''} />
                                {this.state.inputError.enderecoEntregaEstado && <small style={{ color: 'red' }}>Estado inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>UF</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaUf && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            uf: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.uf || ''} />
                                {this.state.inputError.enderecoEntregaUf && <small style={{ color: 'red' }}>Uf inválido</small>}
                            </div>
                        </div>}

                    {this.state.indexStepAtivo === 3 &&
                        <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                            <div style={{ width: '100%' }}>
                                <label>Assessor</label><br />
                                <Dropdown
                                    options={this.state.listaPessoaAssessor}
                                    optionLabel="nome"
                                    onChange={pEvento => this.setState({
                                        pessoaAssessor: pEvento.value
                                    })}
                                    style={{ width: "100%", borderColor: this.state.inputError.assessor && 'red' }}
                                    value={this.state.pessoaAssessor || ''} />
                                {this.state.inputError.assessor && <small style={{ color: 'red' }}>Assessor inválida</small>}
                            </div>
                        </div>}

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                        <Button
                            disabled={this.state.indexStepAtivo === 0}
                            style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                            label="Voltar"
                            type="button"
                            onClick={() => this.setState({ indexStepAtivo: this.state.indexStepAtivo - 1 })} />

                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            {this.state.indexStepAtivo === 2 &&
                                <Button
                                    disabled={this.state.usarEnderecoDaCasaDeFestaCarregando}
                                    loading={this.state.usarEnderecoDaCasaDeFestaCarregando}
                                    label="Usar Endereço da Casa de Festa"
                                    type="button"
                                    onClick={() => this.usarEnderecoDaCasaDeFesta()} />}
                            <Button
                                disabled={this.state.salvarCarregando}
                                loading={this.state.salvarCarregando}
                                style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                                label={this.state.indexStepAtivo < 3 ? "Avançar" : "Salvar"}
                                type="submit" />
                        </div>
                    </div>
                </form >
            </Dialog >

            <Toast ref={this.toast} />

            <HeaderAdministrativo />

            <main style={{ padding: 20 }}>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h1 style={{ fontSize: 20 }}>
                            Eventos
                        </h1>

                        <Button
                            style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                            label="+ Novo Evento"
                            onClick={() => this.setState({
                                dialogNovoEvento: true,
                                indexStepAtivo: 0,
                                pessoa: new Pessoa(),
                                enderecoCobranca: {
                                    ...new Endereco(),
                                    seqTipoEndereco: this.constante.seqTipoEnderecoCobranca
                                },
                                enderecoEntrega: {
                                    ...new Endereco(),
                                    seqTipoEndereco: this.constante.seqTipoEnderecoEntrega
                                },
                                documento: new Documento(),
                                inputError: {
                                    nome: false,
                                    tipoDocumento: false,
                                    documento: false,
                                    email: false,
                                    telefone: false,
                                    enderecoCobrancaCep: false,
                                    enderecoCobrancaLogradouro: false,
                                    enderecoCobrancaNumero: false,
                                    enderecoCobrancaBairro: false,
                                    enderecoCobrancaCidade: false,
                                    enderecoCobrancaEstado: false,
                                    enderecoCobrancaUf: false,
                                    numeroContrato: false,
                                    horaEvento: false,
                                    dataEvento: false,
                                    numeroConvidado: false,
                                    observacao: false,
                                    enderecoEntregaCep: false,
                                    enderecoEntregaLogradouro: false,
                                    enderecoEntregaNumero: false,
                                    enderecoEntregaBairro: false,
                                    enderecoEntregaCidade: false,
                                    enderecoEntregaEstado: false,
                                    enderecoEntregaUf: false,
                                },
                            })} />
                    </div>
                </div>


                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div>
                        <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: this.constante.corSecundaria, textDecorationThickness: 2 }}>
                            <i className="pi pi-filter-fill" style={{ color: this.constante.corSecundaria }} /> Filtros
                        </h3>

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 20 }}>
                            <div style={{ width: "100%" }}>
                                <label>Nome do Cliente</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        conteudo: pEvento.target.value
                                    })}
                                    value={this.state.conteudo} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Email do Cliente</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        conteudoEmail: pEvento.target.value
                                    })}
                                    value={this.state.conteudoEmail} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de cadastro inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroInicial: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de cadastro final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroFinal: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do evento inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataEventoInicial: pEvento.value,
                                    })}
                                    value={this.state.dataEventoInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do evento final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataEventoFinal: pEvento.value,
                                    })}
                                    value={this.state.dataEventoFinal} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button
                            disabled={this.state.carregando}
                            loading={this.state.carregando}
                            style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                            label="Buscar"
                            onClick={() => this.listar()} />
                    </div>
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    showGridlines
                    removableSort
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={50}
                    rowsPerPageOptions={[50, 100]}
                    value={this.state.listaDocumento}
                    onSelectionChange={pEvento => window.open('/administrativo/evento_detalhe?id=' + pEvento.value._seqDocumento)}>
                    <Column
                        sortable
                        header="Código do Evento"
                        field="_codigo" />
                    <Column
                        sortable
                        header="Número contrato"
                        body={pDocumento => <a
                            href={"/administrativo/evento_detalhe?id=" + pDocumento._seqDocumento}
                            target="_blank">
                            {pDocumento.tags0}
                        </a>} />
                    <Column
                        sortable
                        header="Nome do Cliente"
                        body={pDocumento => <a
                            href={"/administrativo/evento_detalhe?id=" + pDocumento._seqDocumento}
                            target="_blank">
                            {pDocumento._pessoaNome}
                        </a>} />
                    <Column
                        sortable
                        header="Email do Cliente"
                        field="_pessoaEmail" />
                    <Column
                        sortable
                        header="Casa de Festa"
                        field="_vendedorNome" />
                    <Column
                        sortable
                        header="Data de cadastro"
                        field="dataCadastro"
                        body={pDocumento => <p>
                            {pDocumento.dataCadastro?.toLocaleDateString("pt-br")}
                        </p>} />
                    <Column
                        sortable
                        header="Data do evento"
                        field="dataProgramada"
                        body={pDocumento => <p>
                            {pDocumento.dataProgramada?.toLocaleDateString("pt-br")}
                        </p>} />
                    <Column
                        sortable
                        header="Visualizar"
                        body={pDocumento => <Button
                            style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                            icon="pi pi-eye"
                            onClick={() => window.open("/administrativo/evento_detalhe?id=" + pDocumento._seqDocumento)} />} />
                </DataTable>
            </main>
        </div >
    }
}