import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import HeaderAdministrativo from "../../components/HeaderAdministrativo";
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import DocumentoCaracteristicaDocumentoService from 'lirida-back-service/Servicos/DocumentoCaracteristicaDocumento/DocumentoCaracteristicaDocumentoService';
import { Column } from "primereact/column";
import { Checkbox, CheckBox } from "primereact/checkbox";

export default class EventoDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.urlBase);
    documentoCaracteristicaDocumentoService = new DocumentoCaracteristicaDocumentoService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        documento: new Documento(),
        pessoa: new Pessoa(),
        listaPessoaAssessor: [],
        listaDocumentoCaracteristicaDocumento: [],
        pessoaAssessor: new Pessoa(),
        pessoaCasaDeFesta: new Pessoa(),
        enderecoCobranca: new Endereco(),
        enderecoEntrega: new Endereco(),
        carregando: false,
        enderecoCobrancaCarregando: false,
        enderecoEntregaCarregando: false,
        inputError: {
            nome: false,
            documento: false,
            email: false,
            telefone: false,
            enderecoCobrancaCep: false,
            enderecoCobrancaLogradouro: false,
            enderecoCobrancaNumero: false,
            enderecoCobrancaBairro: false,
            enderecoCobrancaCidade: false,
            enderecoCobrancaEstado: false,
            enderecoCobrancaUf: false,
            numeroContrato: false,
            casaDeFesta: false,
            dataEvento: false,
            observacao: false,
            enderecoEntregaCep: false,
            enderecoEntregaLogradouro: false,
            enderecoEntregaNumero: false,
            enderecoEntregaBairro: false,
            enderecoEntregaCidade: false,
            enderecoEntregaEstado: false,
            enderecoEntregaUf: false,
            assessor: false,
        },
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let documento = new Documento();
        let pessoa = new Pessoa();
        let pessoaAssessor = new Pessoa();
        let pessoaCasaDeFesta = new Pessoa();
        let enderecoCobranca = new Endereco();
        let enderecoEntrega = new Endereco();
        let listaEndereco = [];
        let listaPesquisa = [];

        let parametro = this.util.buscarParametrosUrl();

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_documento';
        pesquisa.conteudo = parametro.id;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        documento = retorno.objeto[0];

        documento.dataProgramada = this.util.formatarDataBancoParaObjetoData(documento.dataProgramada);

        retorno = await this.pessoaService.buscarPorSeqPessoa(documento.seqPessoa, this.constante.seqUsuario);
        pessoa = retorno.objeto;

        listaPesquisa = [];

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilAssessor;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        let listaPessoaAssessor = retorno.objeto;

        retorno = await this.pessoaService.buscarPorSeqPessoa(documento.tags1, this.constante.seqUsuario);
        pessoaAssessor = retorno.objeto;

        retorno = await this.pessoaService.listarEndereco(pessoa);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                enderecoCobranca = listaEndereco[i];
            }
        }

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
                enderecoEntrega = listaEndereco[i];
            }
        }

        await this.listarCaracteristica(documento);

        this.setState({
            documento: documento,
            pessoa: pessoa,
            pessoaAssessor: pessoaAssessor,
            listaPessoaAssessor: listaPessoaAssessor,
            pessoaCasaDeFesta: pessoaCasaDeFesta,
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
        })
    }

    async listarCaracteristica(pDocumento) {
        let retorno = await this.documentoCaracteristicaDocumentoService.listar(pDocumento);
        let listaDocumentoCaracteristicaDocumento = retorno.objeto;

        console.log(listaDocumentoCaracteristicaDocumento);

        this.setState({
            listaDocumentoCaracteristicaDocumento: listaDocumentoCaracteristicaDocumento,
        });
    }

    async salvar(pDocumento) {
        let pessoa = this.state.pessoa;
        let documento = this.state.documento;
        let enderecoCobranca = this.state.enderecoCobranca;
        let enderecoEntrega = this.state.enderecoEntrega;

        if (pDocumento) {
            documento = pDocumento;
        }

        if (!await this.validarInputError()) {
            return;
        };

        this.setState({ carregando: true });

        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        retorno = await this.pessoaService.salvarEndereco(enderecoCobranca);
        enderecoCobranca = retorno.objeto;

        retorno = await this.pessoaService.salvarEndereco(enderecoEntrega);
        enderecoEntrega = retorno.objeto;

        documento.dataProgramada = this.util.formatarObjetoDataParaDataBanco(documento.dataProgramada);
        documento.tags1 = this.state.pessoaAssessor._seqPessoa;

        retorno = await this.documentoService.salvar(documento);
        documento = retorno.objeto;

        documento.dataProgramada = this.util.formatarDataBancoParaObjetoData(documento.dataProgramada);

        if (!pDocumento) {
            this.toast.current.show({
                severity: 'success', summary: "Informações armazenadas com sucesso!"
            })
        }

        this.setState({
            carregando: false,
            pessoa: pessoa,
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            documento: documento,
        });

        return documento;
    }

    async buscarCep(pEndereco) {
        let endereco = new Endereco();
        let enderecoCobranca = this.state.enderecoCobranca;
        let enderecoEntrega = this.state.enderecoEntrega;
        let inputError = this.state.inputError;
        let inputsOK = true;

        endereco = pEndereco;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!endereco.cep) {
            inputError.cep = true;
        }

        if (!this.util.validarCep(endereco.cep)) {
            inputError.cep = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
            this.setState({ enderecoCobrancaCarregando: true });
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
            this.setState({ enderecoEntregaCarregando: true });
        }

        let retorno = await this.cepService.buscarCep(endereco.cep);

        endereco.logradouro = retorno.logradouro;
        endereco.complemento = retorno.complemento;
        endereco.bairro = retorno.bairro;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.uf = retorno.uf;

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
            enderecoCobranca = endereco;
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
            enderecoEntrega = endereco;
        }

        this.setState({
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            enderecoCobrancaCarregando: false,
            enderecoEntregaCarregando: false,
        });
    }

    async validarInputError() {
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.pessoa.nome) {
            inputError.nome = true;
        }

        if (!this.state.pessoa.documento) {
            inputError.documento = true;
        } else if (this.state.pessoa.tipoDocumento === 'CPF' && this.state.pessoa.documento.length < 14) {
            inputError.documento = true;
        } else if (this.state.pessoa.tipoDocumento === 'CNPJ' && this.state.pessoa.documento.length < 18) {
            inputError.documento = true;
        } else if (!await this.util.validarDocumento(this.state.pessoa.documento)) {
            inputError.documento = true;
        }

        if (!this.state.pessoa.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(this.state.pessoa.emailPlataformaLirida)) {
            inputError.email = true;
        }

        if (!this.state.pessoa.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.state.enderecoCobranca.cep) {
            inputError.enderecoCobrancaCep = true;
        }

        if (!this.util.validarCep(this.state.enderecoCobranca.cep)) {
            inputError.enderecoCobrancaCep = true;
        }

        if (!this.state.enderecoCobranca.logradouro) {
            inputError.enderecoCobrancaLogradouro = true;
        }

        if (!this.state.enderecoCobranca.numero) {
            inputError.enderecoCobrancaNumero = true;
        }

        if (!this.state.enderecoCobranca.bairro) {
            inputError.enderecoCobrancaBairro = true;
        }

        if (!this.state.enderecoCobranca.cidade) {
            inputError.enderecoCobrancaCidade = true;
        }

        if (!this.state.enderecoCobranca.uf) {
            inputError.enderecoCobrancaUf = true;
        }

        if (!this.state.documento.tags0) {
            inputError.numeroContrato = true;
        }

        if (!this.state.documento.dataProgramada) {
            inputError.dataEvento = true;
        }

        if (!this.state.enderecoEntrega.cep) {
            inputError.enderecoEntregaCep = true;
        }

        if (!this.util.validarCep(this.state.enderecoEntrega.cep)) {
            inputError.enderecoEntregaCep = true;
        }

        if (!this.state.enderecoEntrega.logradouro) {
            inputError.enderecoEntregaLogradouro = true;
        }

        if (!this.state.enderecoEntrega.numero) {
            inputError.enderecoEntregaNumero = true;
        }

        if (!this.state.enderecoEntrega.bairro) {
            inputError.enderecoEntregaBairro = true;
        }

        if (!this.state.enderecoEntrega.cidade) {
            inputError.enderecoEntregaCidade = true;
        }

        if (!this.state.enderecoEntrega.uf) {
            inputError.enderecoEntregaUf = true;
        }

        if (!this.state.pessoaAssessor._seqPessoa) {
            inputError.assessor = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        console.log(inputError);

        this.setState({
            inputError: inputError
        });

        if (!inputsOK) {
            this.toast.current.show({
                severity: 'error', summary: 'Informe todos os campos corretamente', sticky: true
            });
        }

        return inputsOK;
    }

    async selecionarDocumentoCaracteristicaDocumento(pDocumentoCaracteristicaDocumento) {
        let listaDocumentoCaracteristicaDocumento = this.state.listaDocumentoCaracteristicaDocumento;

        for (let i = 0; i < listaDocumentoCaracteristicaDocumento.length; i++) {
            if (listaDocumentoCaracteristicaDocumento[i].seqDocumentoCaracteristica === pDocumentoCaracteristicaDocumento.seqDocumentoCaracteristica) {
                if (listaDocumentoCaracteristicaDocumento[i].marcado === 'false') {
                    listaDocumentoCaracteristicaDocumento[i].marcado = 'true';
                } else {
                    listaDocumentoCaracteristicaDocumento[i].marcado = 'false';
                }
            }
        }

        console.log(listaDocumentoCaracteristicaDocumento);

        let retorno = await this.documentoCaracteristicaDocumentoService.salvar(listaDocumentoCaracteristicaDocumento);
        console.log(retorno);

        this.setState({
            listaDocumentoCaracteristicaDocumento: listaDocumentoCaracteristicaDocumento,
        });
    }

    render() {
        return <div style={{ paddingBottom: 100 }}>
            <Toast ref={this.toast} />

            <HeaderAdministrativo />

            <main style={{ padding: 20 }}>
                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <h1 style={{ fontSize: 20 }}>
                                Detalhe do Evento
                            </h1>
                            <h2 style={{ fontSize: 20, fontWeight: 'normal', color: 'gray', marginTop: 5 }}>
                                Nº {this.state.documento._codigo}
                            </h2>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <Button
                                disabled={!this.state.documento._seqDocumento || this.state.carregando}
                                loading={this.state.carregando}
                                style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                                label="Salvar"
                                onClick={() => this.salvar()} />
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: this.constante.corSecundaria, textDecorationThickness: 2 }}>
                    <i className="pi pi-user" style={{ color: this.constante.corSecundaria }} /> Dados do Cliente
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 10 }}>
                    <h4 style={{ fontSize: 16, }}>
                        <span style={{ color: this.constante.corSecundaria }}>•</span> Dados pessoais
                    </h4>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Nome</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.nome || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoa</label><br />
                            <Dropdown
                                disabled={this.state.documento.tagd0}
                                options={[
                                    { label: 'Pessoa física', value: 'CPF' },
                                    { label: 'Pessoa jurídica', value: 'CNPJ' },
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        tipoDocumento: pEvento.value
                                    }
                                })}
                                style={{ width: "100%" }}
                                value={this.state.pessoa.tipoDocumento || ''} />
                        </div>
                        {this.state.pessoa.tipoDocumento === 'CPF' &&
                            <div style={{ width: '100%' }}>
                                <label>CPF</label><br />
                                <InputText
                                    disabled={this.state.documento.tagd0}
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCPF(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento || ''} />
                            </div>}
                        {this.state.pessoa.tipoDocumento === 'CNPJ' &&
                            <div style={{ width: '100%' }}>
                                <label>CNPJ</label><br />
                                <InputText
                                    disabled={this.state.documento.tagd0}
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCNPJ(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento || ''} />
                            </div>}
                        <div style={{ width: '100%' }}>
                            <label>Email</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.emailPlataformaLirida || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        telefonePlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.telefonePlataformaLirida || ''} />
                        </div>
                    </div>

                    <h4 style={{ fontSize: 16, marginTop: 10, }}>
                        <span style={{ color: this.constante.corSecundaria }}>•</span> Endereço
                    </h4>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>CEP</label><br />
                            <div className="p-inputgroup">
                                <InputText
                                    disabled={this.state.documento.tagd0}
                                    value={this.state.enderecoCobranca.cep || ''}
                                    type="tel"
                                    style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoCobranca: {
                                            ...this.state.enderecoCobranca,
                                            cep: pEvento.target.value
                                        }
                                    })}
                                />
                                <Button
                                    disabled={this.state.enderecoCobrancaCarregando}
                                    loading={this.state.enderecoCobrancaCarregando}
                                    style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                                    icon="pi pi-search"
                                    type="button"
                                    onClick={() => this.buscarCep(this.state.enderecoCobranca)}
                                />
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Logradouro</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        logradouro: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.logradouro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Número</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        numero: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.numero || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Complemento</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        complemento: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.complemento || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Bairro</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        bairro: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.bairro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Cidade</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        cidade: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.cidade || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Estado</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        estado: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.estado || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>UF</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        uf: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.uf || ''} />
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: this.constante.corSecundaria, textDecorationThickness: 2 }}>
                    <i className="pi pi-book" style={{ color: this.constante.corSecundaria }} /> Dados do Evento
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 10 }}>
                    <div style={{ width: '100%', marginTop: 10 }}>
                        <label>Casa de Festa</label><br />
                        <InputText
                            style={{ width: "100%" }}
                            disabled
                            value={this.state.documento._vendedorNome || ''} />
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Número do contrato</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    documento: {
                                        ...this.state.documento,
                                        tags0: pEvento.target.value
                                    }
                                })}
                                value={this.state.documento.tags0 || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Data do evento</label><br />
                            <Calendar
                                disabled={this.state.documento.tagd0}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                inputStyle={{ borderColor: this.state.inputError.dataEvento && 'red' }}
                                style={{ width: "100%" }}
                                onChange={pEvento => {
                                    this.setState({
                                        documento: {
                                            ...this.state.documento,
                                            dataProgramada: pEvento.value
                                        }
                                    })
                                }}
                                value={this.state.documento.dataProgramada} />
                        </div>
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <label>Observação</label><br />
                        <InputText
                            disabled={this.state.documento.tagd0}
                            style={{ width: "100%" }}
                            onChange={pEvento => this.setState({
                                documento: {
                                    ...this.state.documento,
                                    observacao: pEvento.target.value
                                }
                            })}
                            value={this.state.documento.observacao || ''} />
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: this.constante.corSecundaria, textDecorationThickness: 2 }}>
                    <i className="pi pi-building" style={{ color: this.constante.corSecundaria }} /> Local do evento
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 10 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>CEP</label><br />
                            <div className="p-inputgroup">
                                <InputText
                                    disabled={this.state.documento.tagd0}
                                    value={this.state.enderecoEntrega.cep || ''}
                                    type="tel"
                                    style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            cep: pEvento.target.value
                                        }
                                    })}
                                />
                                <Button
                                    disabled={this.state.enderecoEntregaCarregando}
                                    loading={this.state.enderecoEntregaCarregando}
                                    style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corSecundaria, borderColor: this.constante.corPrincipal }}
                                    icon="pi pi-search"
                                    type="button"
                                    onClick={() => this.buscarCep(this.state.enderecoEntrega)}
                                />
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Logradouro</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        logradouro: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.logradouro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Número</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        numero: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.numero || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Complemento</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        complemento: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.complemento || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Bairro</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        bairro: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.bairro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Cidade</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        cidade: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.cidade || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Estado</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        estado: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.estado || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>UF</label><br />
                            <InputText
                                disabled={this.state.documento.tagd0}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        uf: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.uf || ''} />
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: this.constante.corSecundaria, textDecorationThickness: 2 }}>
                    <i className="pi pi-user" style={{ color: this.constante.corSecundaria }} /> Dados do Assessor
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 10 }}>
                    <div style={{ width: '100%' }}>
                        <label>Assessor</label><br />
                        <Dropdown
                            options={this.state.listaPessoaAssessor}
                            optionLabel="nome"
                            onChange={pEvento => this.setState({
                                pessoaAssessor: pEvento.value
                            })}
                            style={{ width: "100%", borderColor: this.state.inputError.assessor && 'red' }}
                            value={this.state.pessoaAssessor || ''} />
                        {this.state.inputError.assessor && <small style={{ color: 'red' }}>Assessor inválida</small>}
                    </div>
                </div>

                <TabView style={{ marginTop: 20 }}>
                    <TabPanel header="Características">
                        <DataTable
                            style={{ marginTop: 20 }}
                            showGridlines
                            removableSort
                            responsiveLayout="scroll"
                            value={this.state.listaDocumentoCaracteristicaDocumento}>
                            <Column
                                header="marcado"
                                field="marcado"
                                body={pDocumentoCaracteristicaDocumento => <Checkbox
                                    onChange={() => this.selecionarDocumentoCaracteristicaDocumento(pDocumentoCaracteristicaDocumento)}
                                    checked={pDocumentoCaracteristicaDocumento.marcado === "true" ? true : false} />} />
                            <Column
                                header="Nome"
                                field="_nome" />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Fornecedores">

                    </TabPanel>
                </TabView>
            </main>
        </div>
    }
}